<template>
  <div>
    <CRow>
      <!-- 좌측 메뉴 -->
      <CCol col="4" class="py-2">
        <!-- 프로필 사진 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
          <CRow>
            <CCol col="3" class="py-2" style="display: flex; align-items: center; justify-content: center;">
              <img :src="require('@/assets/images/storeManagement_inventory/dummy.webp')" style="width: 100%;" />
            </CCol>
            <CCol col="5" class="py-2">
              <CCol col="12" class="py-2">
                <span style="color: #D7E400">LV.0</span>
                {{partnerIndexCompanyName}}
              </CCol>
              <CCol col="12" class="py-2">
                <img :src="require('@/assets/images/storeManagement_inventory/green.png')" style="height: 100%;" />
                <span style="font-weight: bold;">영업중</span>
              </CCol>
            </CCol>
            <CCol col="4" class="py-2" style="display: flex; align-items: center; justify-content: flex-end;">
              <CCol col="12" class="py-2">
                미니샵 방문 : 10
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 영업상태 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              영업상태 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_workState_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 영업/출장 시간 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              영업/출장 시간 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_workTimeSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 수리의뢰 옵션 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              수리의뢰 옵션 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_workOptionSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 매장소개 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              매장소개 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_companyInfoSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 할인 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              할인 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_discountSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 보유재고 변경 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              보유재고 변경
            </CCol>
            <CCol col="4" class="py-2">
              <CLink style="text-decoration-line: none; color: black;"
                to="/c_storeManagement_inventory"
              >
                <CCol col="12" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                  설정하기
                </CCol>
              </CLink>
            </CCol>
          </CRow>
        </CCol>
        <!-- 사업자정보 변경/등록 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              사업자정보 변경/등록
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_companyRegister_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 정산 관리 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              정산 관리
            </CCol>
            <CCol col="4" class="py-2">
              <CLink style="text-decoration-line: none; color: black;"
                to="/c_storeManagement_calculate"
              >
                <CCol col="12" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                  설정하기
                </CCol>
              </CLink>
            </CCol>
          </CRow>
        </CCol>
      </CCol>
      <!-- 우측 메인 -->
      <CCol col="8" class="py-2">
        <!-- 보유재고 변경 -->
        <CCol col="12" class="py-2">
          <CRow>
            <CCol col="4" class="py-2" style="font-size: 18px; font-weight: bold;">
              보유재고 변경
            </CCol>
            <CCol col="5" class="py-2">
              <CCol col="12" style="border: 4px #19C1FF solid;">
                <CRow>
                  <CCol col="10" style=" display: flex; align-items: center; justify-content: flex-start;">
                    재고 검색
                  </CCol>
                  <CCol col="2" style="background-color: #19C1FF; display: flex; align-items: center; justify-content: center;">
                    <img :src="require('@/assets/images/storeManagement_inventory/search.png')" style="height: 70%;" />
                  </CCol>
                </CRow>
              </CCol>
            </CCol>
            <CCol col="3" class="py-2" @click="inventory_product_add_modal = true" style="background-color: #19C1FF; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
              재고 추가
            </CCol>
          </CRow>
        </CCol>
        <!-- 내용 종류 노출 -->
        <CCol col="12" class="py-2">
          <CRow>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = 'CPU'"
            >
              CPU
            </CCol>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = '메인보드'"
            >
              메인보드
            </CCol>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = '메모리'"
            >
              메모리
            </CCol>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = '그래픽카드'"
            >
              그래픽카드
            </CCol>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = 'SSD/HDD'"
            >
              SSD/HDD
            </CCol>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = '파워'"
            >
              파워
            </CCol>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = '케이스'"
            >
              케이스
            </CCol>
            <CCol col="2" class="py-2" style="border: 2px #ECECEC solid; display: flex; align-items: center; justify-content: center;"
              @click="choiceType = '기타'"
            >
              기타
            </CCol>
          </CRow>
        </CCol>
        <!-- 내용 노출 -->
        <CCol col="12" class="py-2">
          <div v-if="choiceType == 'CPU'">
            <CRow v-for="item in partnerProductListData_cpu" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <div v-if="choiceType == '메인보드'">
            <CRow v-for="item in partnerProductListData_motherboard" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <div v-if="choiceType == '메모리'">
            <CRow v-for="item in partnerProductListData_memory" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <div v-if="choiceType == '그래픽카드'">
            <CRow v-for="item in partnerProductListData_grapic" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <div v-if="choiceType == 'SSD/HDD'">
            <CRow v-for="item in partnerProductListData_ssd" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <div v-if="choiceType == '파워'">
            <CRow v-for="item in partnerProductListData_power" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <div v-if="choiceType == '케이스'">
            <CRow v-for="item in partnerProductListData_case" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <div v-if="choiceType == '기타'">
            <CRow v-for="item in partnerProductListData_etc" v-bind:key="item.index">
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                        v-for="image in item.product_index.images" v-bind:key="image.id"
                      />
                      <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                        v-if="item.product_index.images.length == 0"
                      />
                  </CCol>
                  <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      {{item.product_index.name}}
                    </CCol>
                    <CCol col="12">
                      판매가격 : {{item.price.toLocaleString()}} 원 / <span @click="priceChange(item)">변경</span>
                    </CCol>
                  </CCol>
                  <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                    <CCol col="12">
                      보유재고
                    </CCol>
                    <CCol col="12">
                      <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity + 1"
                      />
                      &nbsp;&nbsp;{{item.quantity.toLocaleString()}}&nbsp;&nbsp;
                      <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" 
                        @click="item.quantity = item.quantity - 1"
                      />
                      &nbsp;/&nbsp;<span @click="quantityChange(item)">저장</span>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          <!-- <CRow>
            <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
              <CRow>
                <CCol col="2" style="display: grid; align-items: center; justify-content: flex-start;">
                    <img :src="require('@/assets/images/storeManagement_inventory/product.png')" style="width: 100%;" />
                </CCol>
                <CCol col="6" style="display: grid; align-items: center; justify-content: flex-start;">
                  <CCol col="12">
                    AMD 라이젠3-2세대 3200G (피카소)
                  </CCol>
                  <CCol col="12">
                    판매가격 : 103,200 원 / <span>저장</span>
                  </CCol>
                </CCol>
                <CCol col="4" style="display: grid; align-items: center; justify-content: flex-start;">
                  <CCol col="12">
                    보유재고
                  </CCol>
                  <CCol col="12">
                    <img :src="require('@/assets/images/storeManagement_inventory/plus.png')" style="width: 10%;" />
                    &nbsp;&nbsp;1&nbsp;&nbsp;
                    <img :src="require('@/assets/images/storeManagement_inventory/minus.png')" style="width: 10%;" />
                    &nbsp;/&nbsp;<span>저장</span>
                  </CCol>
                </CCol>
              </CRow>
            </CCol>
          </CRow> -->
        </CCol>
      </CCol>
    </CRow>

    <!-- 재고상품 추가 -->
    <CModal
      title="재고상품 추가"
      :show.sync="inventory_product_add_modal"
      size="xl"
    >
      <div class="px-2">
        <!-- 재고명 -->
        <CRow class="mb-2">
          <CCol col="12" style="font-size: 18px; font-weight: bold;">재고명</CCol>
        </CRow>
        <CRow class="mb-4">
          <CCol col="10" style="font-size: 18px;">
            <input v-model="inventory_product_add_title" placeholder="재고 상품을 검색후 설정해주세요" style="width: 100%;" disabled>
          </CCol>
          <CCol col="2">
            <CCol col="12" class="py-2" @click="inventory_product_search_modal = true" style="background-color: #19C1FF; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
              검색
            </CCol>
          </CCol>
        </CRow>
        <!-- 분류 / 가격 -->
        <CRow>
          <CCol col="4">
            <CRow class="mb-2">
              <CCol col="12" style="font-size: 18px; font-weight: bold;">분류</CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol col="12" style="font-size: 18px;">
                <vSelect :options="['CPU', '메인보드', '메모리', '그래픽카드', 'SSD/HDD', '파워', '케이스', '기타']"
                  v-model="inventory_product_add_type"
                ></vSelect>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="4">
            <CRow class="mb-2">
              <CCol col="12" style="font-size: 18px; font-weight: bold;">가격</CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol col="12" style="font-size: 18px;">
                <input v-model="inventory_product_add_price" placeholder="금액을 입력하세요" style="width: 90%;">&nbsp;원
              </CCol>
            </CRow>
          </CCol>
          <CCol col="4">
            <CRow class="mb-2">
              <CCol col="12" style="font-size: 18px; font-weight: bold;">수량</CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol col="12" style="font-size: 18px;">
                <input v-model="inventory_product_add_quantity" placeholder="수량을 입력하세요" style="width: 90%;">&nbsp;원
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <!-- 소개 -->
        <CRow class="mb-2">
          <CCol col="12" style="font-size: 18px; font-weight: bold;">소개</CCol>
        </CRow>
        <CRow class="mb-4">
          <CCol col="12" style="font-size: 18px;">
            <input v-model="inventory_product_add_infomation" placeholder="소개를 입력하세요" style="width: 100%;">
          </CCol>
        </CRow>
        <!-- 수리의뢰 옵션 연결 -->
        <!-- <CRow class="mb-2">
          <CCol col="8" style="font-size: 18px; font-weight: bold;">수리의뢰 옵션 연결</CCol>
          <CCol col="4">
            <CRow>
              <CCol col="4" class="py-2">
                <CCol col="12" style="background-color: #19C1FF; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
                  등록
                </CCol>
              </CCol>
              <CCol col="4" class="py-2">
                <CCol col="12" style="border: 1px red solid; color: red; display: flex; align-items: center; justify-content: center;">
                  해제
                </CCol>
              </CCol>
              <CCol col="4" class="py-2">
                <CCol col="12" style="border: 1px #19C1FF solid; color: #19C1FF; display: flex; align-items: center; justify-content: center;">
                  필수
                </CCol>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="mb-4 mx-2" style="border: 2px #ECECEC solid; align-items: center;">
          <CCol col="5">
            <CCol col="12" style="font-size: 18px;">
              <input v-model="inventory_product_add_option_title" placeholder="옵션명을 입력하세요" style="width: 100%;">
            </CCol>
          </CCol>
          <CCol col="4">
            <CCol col="12" style="font-size: 18px;">
              <input v-model="inventory_product_add_option_price" placeholder="금액을 입력하세요" style="width: 80%;">&nbsp;원
            </CCol>
          </CCol>
          <CCol col="3">
            <CRow>
              <CCol col="6" class="py-2">
                <CCol col="12" style="border: 1px red solid; color: red; display: flex; align-items: center; justify-content: center;">
                  해제
                </CCol>
              </CCol>
              <CCol col="6" class="py-2">
                <CCol col="12" style="border: 1px #19C1FF solid; color: #19C1FF; display: flex; align-items: center; justify-content: center;">
                  필수
                </CCol>
              </CCol>
            </CRow>
          </CCol>
        </CRow> -->
        <!-- 취소 / 추가 -->
        <CRow style="justify-content: center;">
          <!-- <CCol col="2" class="py-2">
            <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
              취소
            </CCol>
          </CCol> -->
          <CCol col="2" class="py-2">
            <CCol col="12" class="py-2" style="background-color: #19C1FF; color: #FFFFFF; display: flex; align-items: center; justify-content: center;"
              @click="inventoryProductAdd()"
            >
              추가
            </CCol>
          </CCol>
        </CRow>
      </div>
    </CModal>

    <CModal
      :show.sync="inventory_product_search_modal"
      size="xl"
    >
      <div>
        <!-- 검색창 -->
        <CRow style="border-bottom: 1px #707070 solid;">
          <CCol col="10" class="py-2" style="display: flex; align-items: center; justify-content: center; font-size: 22px; font-weight: bold;">
            <input v-model="inventory_product_search_title" placeholder="상품명을 입력하세요" style="width: 100%; border: hidden;">
          </CCol>
          <CCol col="2" class="py-2" style="display: flex; align-items: center; justify-content: center;">
            <img :src="require('@/assets/images/storeManagement_inventory/search_black.png')" style="width: 50%;" 
              @click="inventory_product_search()"
            />
          </CCol>
        </CRow>
        <!-- 검색전 -->
        <CRow class="px-5 py-4"
          v-if="inventory_product_search_product_data.length == 0"
        >
          <CCol col="12" class="py-1" style="font-size: 22px; font-weight: bold;">
            tip
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px;">
            아래와 같은 조합으로 검색을 하시면 더욱 정확한 결과가 검색됩니다.
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            제조사 + 용량
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) 삼성 8GB, 마이크로닉스 500W, WD 256GB
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            속도 + 용량
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) 25600 16GB, 21300 8GB
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            제조사 + 칩셋
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) MSI A520, 기가바이트 B560
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            인터페이스 + 용량
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) SATA 256GB, NVME 1TB, DDR4 16GB
          </CCol>
        </CRow>
        <!-- 검색후 -->
        <div v-if="inventory_product_search_product_data.length > 0">
          <CRow class="mx-2 my-2 px-3 py-2" style="border: 1px #F5F5F5 solid;">
            <!-- <CCol col="12" class="py-2">
              <CRow>
                <CCol col="3" style="font-size: 18px;">
                  <vSelect :options="['CPU', '메인보드', '메모리', '그래픽카드', 'SSD/HDD', '파워', '케이스', '기타']"></vSelect>
                </CCol>
                <CCol col="3" style="font-size: 18px;">
                  <vSelect :options="['인텔', 'AMD']"></vSelect>
                </CCol>
              </CRow>
            </CCol> -->
            <CCol col="12" class="py-2"
              v-for="item in inventory_product_search_product_data" v-bind:key="item.index"
            >
              <CRow>
                <CCol col="2">
                  <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                    v-for="image in item.images" v-bind:key="image.id"
                  />
                  <img :src="`https://suriyo.api.togethersoft.co.kr/assets/a9111a1d-559c-4e6a-977e-afa0b718865d`" style="width: 100%;" 
                    v-if="item.images.length == 0"
                  />
                </CCol>
                <CCol col="8" style="font-size: 14px;">
                  <span style="font-size: 15px; font-weight: bold;">{{item.name}}</span><br>
                  <!-- AMD(소켓 AM4) / 3세대 (Zen 2) / 7nm / 6코어 / 12쓰레드 / 기본 클럭 3.6GHz -->
                  유형 : {{item.type}}
                </CCol>
                <CCol col="2" style="display: flex; align-items: center; justify-content: center;">
                  <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;"
                    @click="addProductSelect(item)"
                  >
                    선택
                  </CCol>
                </CCol>
              </CRow>
            </CCol>
            <!-- <CCol col="12" class="py-2">
              <CRow>
                <CCol col="2">
                  <img :src="require('@/assets/images/storeManagement_inventory/product.png')" style="width: 100%;" />
                </CCol>
                <CCol col="8" style="font-size: 14px;">
                  <span style="font-size: 15px; font-weight: bold;">AMD 라이젠5-3세대 3600 (마티스) (멀티팩)</span><br>
                  AMD(소켓 AM4) / 3세대 (Zen 2) / 7nm / 6코어 / 12쓰레드 / 기본 클럭 3.6GHz
                </CCol>
                <CCol col="2" style="display: flex; align-items: center; justify-content: center;">
                  <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
                    선택
                  </CCol>
                </CCol>
              </CRow>
            </CCol> -->
          </CRow>
        </div>
        <!-- 검색 안내 -->
        <CRow style="border-top: 1px #707070 solid;">
          <CCol col="12" class="px-5 py-3" style="font-size: 15px; font-weight: bold;">
            자료 : 다나와 PC견적 제공<br>
            다나와 PC견적에 등록되어 있지 않은 상품은 수기로 등록해야 합니다.<br>
            단, 수기로 등록한 상품은 수리매칭이 이루어지지 않을수 있습니다.
          </CCol>
        </CRow>
      </div>
    </CModal>

    <!-- 영업상태 모달 -->
    <CModal
      title="영업상태 설정"
      :show.sync="storeManagement_workState_modal"
      size="xl"
    >
      <StoreManagementWorkState></StoreManagementWorkState>
    </CModal>

    <!-- 영업시간 모달 -->
    <CModal
      title="영업시간 설정"
      :show.sync="storeManagement_workTimeSetting_modal"
      size="xl"
    >
      <StoreManagementWorkTimeSetting></StoreManagementWorkTimeSetting>
    </CModal>

    <!-- 수리의뢰옵션 모달 -->
    <CModal
      title="수리의뢰옵션 설정"
      :show.sync="storeManagement_workOptionSetting_modal"
      size="xl"
    >
      <StoreManagementWorkOptionSetting></StoreManagementWorkOptionSetting>
    </CModal>

    <!-- 매장소개 모달 -->
    <CModal
      title="매장소개 설정"
      :show.sync="storeManagement_companyInfoSetting_modal"
      size="xl"
    >
      <StoreManagementCompanyInfoSetting></StoreManagementCompanyInfoSetting>
    </CModal>

    <!-- 할인 모달 -->
    <CModal
      title="할인 설정"
      :show.sync="storeManagement_discountSetting_modal"
      size="xl"
    >
      <StoreManagementDiscountSetting></StoreManagementDiscountSetting>
    </CModal>

    <!-- 사업자정보 등록/변경 모달 -->
    <CModal
      title="사업자정보 등록/변경"
      :show.sync="storeManagement_companyRegister_modal"
      size="xl"
    >
      <StoreManagementCompanyRegister></StoreManagementCompanyRegister>
    </CModal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';
import StoreManagementWorkState from './storeManagement_workState';
import StoreManagementWorkTimeSetting from './storeManagement_workTimeSetting';
import StoreManagementWorkOptionSetting from './storeManagement_workOptionSetting';
import StoreManagementCompanyInfoSetting from './storeManagement_companyInfoSetting';
import StoreManagementDiscountSetting from './storeManagement_discountSetting';
import StoreManagementCompanyRegister from './storeManagement_companyRegister';

export default {
  name: 'c_storeManagement_inventory',
  components: {
    vSelect,
    StoreManagementWorkState,
    StoreManagementWorkTimeSetting,
    StoreManagementWorkOptionSetting,
    StoreManagementCompanyInfoSetting,
    StoreManagementDiscountSetting,
    StoreManagementCompanyRegister
  },
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      inventory_product_add_modal: false,
      inventory_product_search_modal: false,
      // 재고상품 추가
      inventory_product_add_title: '',
      inventory_product_add_product_data: null,
      inventory_product_add_type: '',
      inventory_product_add_price: 0,
      inventory_product_add_quantity: 0,
      inventory_product_add_infomation: '',
      inventory_product_add_option_title: '',
      inventory_product_add_option_price: 0,
      // 재고상품 검색
      inventory_product_search_title: '',
      inventory_product_search_product_data: [],
      // 영업상태 모달
      storeManagement_workState_modal: false,
      // 영업시간 모달
      storeManagement_workTimeSetting_modal: false,
      // 수리의뢰옵션 모달
      storeManagement_workOptionSetting_modal: false,
      // 매장소개 모달
      storeManagement_companyInfoSetting_modal: false,
      // 할인 모달
      storeManagement_discountSetting_modal: false,
      // 사업자정보 등록/변경 모달
      storeManagement_companyRegister_modal: false,

      // 등록한 상품 목록
      partnerProductListData: [],
      partnerProductListData_cpu: [],
      partnerProductListData_motherboard: [],
      partnerProductListData_memory: [],
      partnerProductListData_grapic: [],
      partnerProductListData_ssd: [],
      partnerProductListData_power: [],
      partnerProductListData_case: [],
      partnerProductListData_etc: [],
      choiceType: 'CPU',

      userIndex: null,
      partnerIndex: null,
      partnerIndexCompanyName: '',
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndex = localStorage.getItem('partnerIndex');
    this.partnerIndexCompanyName = localStorage.getItem('partnerIndexCompanyName');
    this.init();
  },
  methods: {
    init() {
      // 초기화
      this.partnerProductListData = [];
      this.partnerProductListData_cpu = [];
      this.partnerProductListData_motherboard = [];
      this.partnerProductListData_memory = [];
      this.partnerProductListData_grapic = [];
      this.partnerProductListData_ssd = [];
      this.partnerProductListData_power = [];
      this.partnerProductListData_case = [];
      this.partnerProductListData_etc = [];

      axios.get(`https://suriyo.api.togethersoft.co.kr/items/partner_product_list?fields=*.*,product_index.images.*&filter={"partner_index":"${this.partnerIndex}"}`)
      .then((res) => {
        console.log(res);
        this.partnerProductListData = res.data.data;

        res.data.data.map(item => {
          // 종류별 구분
          if (item.product_index.type == 'CPU') {
            this.partnerProductListData_cpu.push(item);
          } else if (item.product_index.type == '메인보드') {
            this.partnerProductListData_motherboard.push(item);
          } else if (item.product_index.type == '메모리') {
            this.partnerProductListData_memory.push(item);
          } else if (item.product_index.type == '그래픽카드') {
            this.partnerProductListData_grapic.push(item);
          } else if (item.product_index.type == 'SSD/HDD') {
            this.partnerProductListData_ssd.push(item);
          } else if (item.product_index.type == '파워') {
            this.partnerProductListData_power.push(item);
          } else if (item.product_index.type == '케이스') {
            this.partnerProductListData_case.push(item);
          } else if (item.product_index.type == '기타') {
            this.partnerProductListData_etc.push(item);
          }
        })
      })
      .catch((err) => {
        console.log(err);
      })
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    priceChange(item) {
      console.log('상품 가격 변경 : ', item);
      const newPrice = prompt('새로운 상품 가격을 입력해주세요');
      console.log(newPrice);
      if (this.isNumeric(newPrice) && newPrice > 0) {
        const data = {
          "price": newPrice,
        }
        axios.patch(`https://suriyo.api.togethersoft.co.kr/items/partner_product_list/${item.index}`, data)
        .then((res) => {
          console.log(res);
          alert('상품 금액이 변경되었습니다.');
          this.init();
        })
        .catch((err) => {
          console.log(err);
        })
      } else {
        alert('올바른 가격을 입력해주세요');
      }
    },
    quantityChange(item) {
      console.log('상품 수량 변경 : ', item);
      const confirmResult = confirm('보유재고 상태를 변경하시겠습니까?');
      console.log(confirmResult);
      if (confirmResult) {
        if (item.quantity >= 0) {
          const data = {
            "quantity": item.quantity,
          }
          axios.patch(`https://suriyo.api.togethersoft.co.kr/items/partner_product_list/${item.index}`, data)
          .then((res) => {
            console.log(res);
            alert('보유재고가 변경되었습니다.');
            this.init();
          })
          .catch((err) => {
            console.log(err);
          })
        } else {
          alert('올바른 수량을 입력해주세요');
        }
      }
    },
    inventory_product_search() {
      console.log('검색어 : ', this.inventory_product_search_title);
      axios.get(`https://suriyo.api.togethersoft.co.kr/items/product?fields=*.*&filter={"name": {"_contains": "${this.inventory_product_search_title}"}}`)
      .then((res) => {
        console.log(res);
        this.inventory_product_search_product_data = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      })
    },
    addProductSelect(item) {
      // 추가할 상품 검색후 선택 완료시
      console.log(item);
      // 재고상품 추가 모달의 재고명
      this.inventory_product_add_title = item.name;
      // 재고상품 추가 모달의 선택된 상품 정보
      this.inventory_product_add_product_data = item;
      // 검색 모달 종료
      this.inventory_product_search_modal = false;
    },
    inventoryProductAdd() {
      console.log('재고상품 추가 정보', 
        this.inventory_product_add_title,
        this.inventory_product_add_product_data,
        this.inventory_product_add_type,
        this.inventory_product_add_price,
        this.inventory_product_add_quantity,
        this.inventory_product_add_infomation,
      );

      if (
        this.inventory_product_add_product_data == null || 
        this.inventory_product_add_type == '' || 
        this.inventory_product_add_price == 0 || 
        this.inventory_product_add_quantity == 0 || 
        this.inventory_product_add_infomation == '' || 
        this.isNumeric(this.inventory_product_add_price) == false || 
        this.isNumeric(this.inventory_product_add_quantity) == false || 
        this.inventory_product_add_price <= 0 || 
        this.inventory_product_add_quantity <= 0
      ) {
        alert('올바른 정보를 입력해주세요');
      } else {
        const confirmResult = confirm('재고상품을 추가하시겠습니까?')
        if (confirmResult == true) {
          console.log('ok');
          const data = {
            "price": this.inventory_product_add_price,
            "quantity": this.inventory_product_add_quantity,
            "information": this.inventory_product_add_infomation,
            "partner_index": this.partnerIndex,
            "product_index": this.inventory_product_add_product_data.index,
          }
          axios.post(`https://suriyo.api.togethersoft.co.kr/items/partner_product_list`, data)
          .then((res) => {
            console.log(res);
            alert('재고상품이 추가되었습니다.');
            this.inventory_product_add_modal = false;
            this.init();
          })
          .catch((err) => {
            console.log(err);
          })
        }
      }
    }
  },
}
</script>
